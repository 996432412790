body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #edf4fa;
  font-size: 16px;
}
h1,
h2,
p,
li,
a,
button,
span {
  font-family: "Montserrat", sans-serif;
  color: #2e2d32;
}
h1,
h2,
p,
li,
span {
  font-weight: 200;
}
h3 {
  font-weight: 700;
  font-family: "Teko", sans-serif;
  color: #2e2d32;
  line-height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
ul {
  padding: 0;
}
li {
  list-style: none;
  display: inline-block;
}
.container {
  max-width: 1280px;
  margin: 0 auto;
}
.navigation ul {
  margin: 3em 0;
  display: flex;
  justify-content: space-around;
}
.navigation ul li {
  font-size: 2.25rem;
  text-transform: uppercase;
}
.navigation ul button {
  font-size: 2.25rem;
  text-transform: uppercase;
  border: none;
  background: transparent;
  cursor: pointer;
}
.navigation ul button:hover,
.navigation ul button.active {
  font-weight: 700;
  color: #6aa4e1;
}
.weather-cards {
  background: #fff;
  min-height: 880px;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 0px 0px;
  gap: 0px 0px;
  padding: 5px;
  position: relative;
  grid-template-areas: "Main Main Main Main" ". . . .";
}
.weather-cards .main {
  grid-area: Main;
}
.weather-cards .weather-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #edf4fa;
  margin: 5px;
}
.weather-cards .weather-card h2 {
  text-align: center;
  font-size: 2.8rem;
  margin-bottom: 1em;
}
.weather-cards .weather-card h3 {
  font-weight: 300;
  font-size: 4rem;
  margin: 0.5em 0 0;
}
.weather-cards .weather-card:first-child {
  border-radius: 10px 10px 0 0;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: flex-start;
}
.weather-cards .weather-card:first-child svg {
  width: 150px;
  margin-right: 2em;
  margin-top: 14px;
}
.weather-cards .weather-card:first-child .loader svg {
  width: 50px;
  margin: 0;
}
.weather-cards .weather-card:first-child h2 {
  flex: 1 1 100%;
  margin-bottom: 1em;
}
.weather-cards .weather-card:first-child h3 {
  font-size: 8rem;
  margin: 0;
}
.weather-cards .weather-card:first-child h3 span {
  display: block;
  font-size: 2.2rem;
  margin-top: -1.5em;
}
.weather-cards .weather-card:nth-child(2) {
  border-radius: 0 0 0 10px;
}
.weather-cards .weather-card:last-child {
  border-radius: 0 0 10px 0;
}
.weather-icon {
  width: 115px;
}
.weather-icon g path {
  fill: #2e3a5d;
}
.loader svg {
  width: 50px;
  margin: 0;
  -webkit-animation: spinner 0.9s linear infinite;
          animation: spinner 0.9s linear infinite;
}
.loader,
.display-error {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
@-webkit-keyframes spinner {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spinner {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@media (max-width: 660px) {
  .navigation ul {
    flex-direction: column;
    align-items: center;
  }
  .navigation ul li {
    margin-bottom: 1em;
  }
  .navigation ul li:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .weather-cards {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas: "Main Main" ". ." ". .";
  }
  .weather-cards .weather-card:nth-child(2) {
    border-radius: 0;
  }
  .weather-cards .weather-card:last-child {
    border-radius: 0 0 10px 10px;
  }
}
